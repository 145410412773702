const env = {
    // apiHost: "http://localhost:8085/",
    apiHost: "https://tictactrump.io:8085/",
    TTTusdtprice: 0.05,
    // testnet
    // stakeContractAddressNew: "0x87D250E3890878d93f146058E454Aeb50dEc520e",
    // tokenAddres: "0x86f4CdDB8A1Dc2F642a7c5304a0fdbd70994C469",
    // percentage: 1,
    // usdtAddres: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    // BuyAddress: "0x7e72ba340543633064ddfc5ff4e7bf1033742f71",
    // rpc_url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // explorerUrl: "https://testnet.bscscan.com/",


    // mainnet
    stakeContractAddressNew: "0xb394fda68effd24382c51b86a5bcbf61ac1e3de1",
    tokenAddres: "0x1e28c7384dcfa428c435a60df8486880c7e2c62d",
    percentage: 1,
    usdtAddres: "0x55d398326f99059fF775485246999027B3197955",
    BuyAddress: "0xb80eb5763bc16641acc0932aed2248f0f3928be5",
    rpc_url: "https://bsc-dataseed1.binance.org/",
    explorerUrl: "https://bscscan.com/",

};


export default env;  