import React, { createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { ToastContainer, toast } from "react-toastify";
import Buy from "./pages/Buy";
import "react-toastify/dist/ReactToastify.css";
import Stake from "./pages/Stake";
import '../src/App.css'
import WalletContextProvider from './WalletContextProvider'; // Import the provider




function App() {
  return (
    <>
      <WalletContextProvider>
      <BrowserRouter>
      <ToastContainer />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/stake" element={<Stake />} />
        </Routes>
      </BrowserRouter>
      </WalletContextProvider>
    </>
  );
}

export default App;
