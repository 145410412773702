import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Lightlogo from "../Images/Logo1.png";
import Darklogo from "../Images/Logo1.png";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
  useWeb3ModalAccount
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";
import Web3 from "web3";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Wallet from "@mui/icons-material/Wallet";
import { toast } from "react-toastify";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Link } from "react-router-dom";
import BNBforTokenABI from "./BNBforTokenABI.json";
import WalletContext from "../WalletContext";

import env from "../core/service/envconfig";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

const Header = () => {
  const projectId = "0b8444fa79de9a3821c5410564de263f";

  const mainnet = {
    chainId: 0x38,
    name: "Binance Smart Chain",
    currency: "BNB",
    explorerUrl: "https://bscscan.com/",
    rpcUrl: "https://bsc-dataseed1.binance.org/",

    // chainId: 0x61,
    // explorerUrl: "https://testnet.bscscan.com/",
    // rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // name: "Binance Smart Chain Testnet",
    // currency: "tBNB",
  };
  const chains = [mainnet];
  const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Solana Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.mywebsite.com/"],
  };
  const ethersConfig = defaultConfig({
    metadata,
    chains,
    projectId,
  });
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
  });

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();
  const {  chainId, isConnected } = useWeb3ModalAccount()

  const classes1 = useStyles1();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [address, setaddress] = useState("");
  const [balance, setbalance] = useState("false");
  const [active, setactive] = useState("buy");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleDrawerOptionClick = () => {
    setMobileMenuOpen(false);
  };

  const [web3, setWeb3] = useState(null);

  const { userWallet, setuserWallet } = useContext(WalletContext);

  async function connectToMetaMask() {
    handlepopClose();
    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });
      try {
        if (!checkForPhantomWallet()) {
          if (
            typeof window.ethereum !== "undefined" &&
            window.ethereum.isMetaMask == true
          ) {
            try {
              await window.ethereum.request({ method: "eth_requestAccounts" });

              const bscNetwork = {
                chainId: "0x61",
                chainName: "Binance Smart Chain Testnet",
                rpcUrls: [env.rpc_url],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://testnet.bscscan.com"],
              };

              await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: bscNetwork.chainId }],
              });

              const web3Instance = new Web3(window.ethereum);
              console.log(web3Instance);
              setWeb3(web3Instance);

              const accounts = await web3Instance.eth.getAccounts();
              console.log(accounts[0]);
              setaddress(accounts[0]);

              // Store address in local storage
              localStorage.setItem("userAddress", accounts[0]);

              const weiBalance = await web3Instance.eth.getBalance(accounts[0]);
              const ethBalance = web3Instance.utils.fromWei(
                weiBalance,
                "ether"
              );
              console.log(ethBalance);
              setbalance(ethBalance);
              localStorage.setItem("Balance", ethBalance);

              const Tokencontarct = new web3Instance.eth.Contract(
                BNBforTokenABI,
                env.tokenAddres
              );
              const tokenBalance = await Tokencontarct.methods
                .balanceOf(accounts[0])
                .call();
              localStorage.setItem(
                "TTTBalance",
                Number(tokenBalance) / 1000000
              );
              localStorage.setItem("wallettype", "metamask");

              initializeWeb3();

              Loading.remove();
            } catch (error) {
              localStorage.setItem("wallettype", "metamask");

              console.error(error);
              // toast.error("Connect with MetaMask");
              window.location.reload();
              Loading.remove();
            }
          } else {
            toast.error("MetaMask not found");
            console.error("MetaMask not found");
            Loading.remove();
          }
        } else {
          Loading.remove();
        }
      } catch (err) {
        Loading.remove();
      }
    } catch (err) {
      Loading.remove();
    }
  }

  // Function to disconnect wallet
  async function  disconnectWallet() {
    Loading.hourglass("Disconnecting...", {
      messageColor: "#fff",
      svgColor: "#fff",
      svgSize: "100px",
      backgroundColor: "rgb(27 138 59 / 68%)",
    });
    localStorage.clear();
    const provider = new BrowserProvider(walletProvider);
    console.log('Provider:', provider);
console.log('Provider emit method:', provider?.emit);
    try {
      if (provider && typeof provider?.emit === 'function') {
        await disconnect();
      } else {
        console.error('Provider or emit function is not available.');
      }
    } catch (error) {
      console.error('Error occurred while disconnecting:', error);
    }
    
    setaddress("");
    setAnchorEl(null);
    initializeWeb3();
    Loading.remove();
  }

  useEffect(() => {
    initializeWeb3();
  }, []);

  const initializeWeb3 = async () => {
    const storedAddress = localStorage.getItem("userAddress");
    if (storedAddress != null) {
      setaddress(storedAddress);
    }
    const URL = window.location.href;
    const splits = URL.split("/");
    const lastSegment = splits[splits.length - 1];
    setactive(lastSegment);
  };

  function checkForPhantomWallet() {
    if (typeof window.solana !== "undefined" && window.solana.isPhantom) {
      alert(
        "Phantom Wallet is detected. Please disable Phantom Wallet extension for Use MetaMask."
      );
      return true;
    } else {
      return false;
    }
  }

  const [open1, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlepopClose = () => {
    setOpen(false);
  };


  const getbalanceaddress = async () => {
    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });
      try {
        if (!isConnected) throw Error('User disconnected');

        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        console.log("address", address);
        setaddress(address);
        const balance = await provider.getBalance(address);
        localStorage.setItem("userAddress", address);
        const ethBalance = parseInt(balance) / 1000000000000000000;
        setbalance(ethBalance);
        localStorage.setItem("Balance", ethBalance);
        const bnbProvider = new JsonRpcProvider(env.rpc_url);
        const Tokencontarct = new Contract(
          env.tokenAddres,
          BNBforTokenABI,
          bnbProvider
        );
        const tokenBalance = await Tokencontarct.balanceOf(address);
        localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000);
        localStorage.setItem("wallettype", "wallet-connect");
        initializeWeb3();
        Loading.remove();
      } catch (error) {
        console.error(error);
        Loading.remove();
      }
    } catch (err) {
      Loading.remove();
    }
  }
  
  if (isConnected) {
    const Useradd = localStorage.getItem("userAddress");
    if (Useradd == null) {
      getbalanceaddress();
    } else {
    }
  } else {
  }

  const handleWalletConnect = async () => {
    handlepopClose();
    await open();

    console.log("hihihihihiihihiihihihihihiihihihihihihihihihihihihi");
   
  };

  return (
    <div>
      <header className="scrolled">
        <div className="">
          <div className="header_new_mega">
            <div className={`${classes.root} `}>
              <AppBar position="static">
                <Toolbar className="container">
                  <Typography variant="h6" className={classes.title}>
                    <div className="logo_new_sectio d-flex">
                      <Link to="/" className="logo-brand">
                        <img
                          src={Lightlogo}
                          className="img-fluid "
                          alt="logo"
                        />
                      </Link>
                      <div className={`menu_new_typr ${classes.appBarItems}`}>
                        <a href="/" color="inherit" className="contact_button">
                          Home
                        </a>
                      </div>
                    </div>
                  </Typography>

                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                  >
                    {address === "" ? (
                      <Button className="nav-btn" onClick={handleClickOpen}>
                        <img
                          src={require("../Images/Profile.png")}
                          width="40px"
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          className="dropdown-toggle text-white"
                          onClick={handleClick}
                        >
                          {address === "" ? (
                            <img
                              src={require("../Images/Profile.png")}
                              width="40px"
                            />
                          ) : (
                            <>
                              <img
                                src={require("../Images/Profile.png")}
                                width="40px"
                              />{" "}
                              {address.substring(0, 2)} ...
                            </>
                          )}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={disconnectWallet}>
                            <LogoutIcon />{" "}
                            <span className="mx-3">Disconnect</span>{" "}
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                    <MenuIcon onClick={handleMobileMenuOpen} />
                  </IconButton>
                  <div className={`menu_new_typr ${classes.appBarItems}`}>
                    <Link
                      className={
                        active === "buy"
                          ? "contact_button active"
                          : "contact_button"
                      }
                      to="/buy"
                    >
                      Buy
                    </Link>
                    <Link
                      className={
                        active === "stake"
                          ? "contact_button active"
                          : "contact_button"
                      }
                      to="/stake"
                    >
                      Stake
                    </Link>

                    {address === "" ? (
                      <Button className="nav-btn" onClick={handleClickOpen}>
                        <Wallet className="mx-2" /> Connect
                      </Button>
                    ) : (
                      <>
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          className="dropdown-toggle"
                          onClick={handleClick}
                        >
                          <Wallet className="mx-2" />{" "}
                          {address === ""
                            ? "Connect Wallet"
                            : `${address.substring(0, 8)} ...`}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={disconnectWallet}>
                            <LogoutIcon />{" "}
                            <span className="mx-3">Disconnect</span>{" "}
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </div>
                </Toolbar>
              </AppBar>
              <Drawer
                anchor="left"
                open={mobileMenuOpen}
                onClose={handleMobileMenuClose}
                className={classes.drawer}
              >
                <div className={classes.mobileMenu}>
                  <div className="logo_new_sectio mobile_menu_icon">
                    <Link to="/" className="logo-brand">
                      <img
                        src={Darklogo}
                        className="img-fluid m-3"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="menu_statis">
                    <ListItem button className="drawa">
                      <Link to="/">Home</Link>
                    </ListItem>
                    <ListItem button className="drawa">
                      <Link to="/buy">Buy</Link>
                    </ListItem>
                    <ListItem button className="drawa ">
                      <Link to="/stake">Stake</Link>
                    </ListItem>
                  </div>
                </div>
              </Drawer>
            </div>

            <Dialog
              open={open1}
              onClose={handlepopClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="wallet-popup"
            >
              <DialogTitle
                id="alert-dialog-title"
                className="d-flex justify-content-between"
              >
                <div>Connect Wallet</div>
                <i
                  className="fa-solid fa-xmark cursor-pointer d-flex align-items-center"
                  onClick={handlepopClose}
                ></i>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="pop-card-head">
                    <div className="pop-card dnones" onClick={connectToMetaMask}>
                      <img
                        src={require("../Images/MetaMask.png")}
                        width="50px"
                      />
                      <p>Metamask</p>
                    </div>
                    <div className="pop-card" onClick={handleWalletConnect}>
                      <img
                        src={require("../Images/walletconnect.png")}
                        width="50px"
                      />
                      <p>Wallet Connect</p>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
