import React, { useState } from 'react';
import WalletContext from './WalletContext';

const WalletContextProvider = ({ children }) => {
  const [userWallet, setuserWallet] = useState(localStorage.getItem("userAddress"));

  const value = { userWallet, setuserWallet };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletContextProvider;