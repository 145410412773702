import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { Link } from "react-router-dom";
import env from "../core/service/envconfig";

function Home() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    AOS.init();

    // Function to handle AOS attributes removal
    const handleAOS = () => {
      if (window.innerWidth < 600) {
        document.querySelectorAll("[data-aos]").forEach((el) => {
          el.setAttribute("data-aos", "");
          el.setAttribute("data-aos-delay", "");
          el.setAttribute("data-aos-duration", "");
        });
      } else {
        document.querySelectorAll(".aos-element").forEach((el) => {
          el.setAttribute("data-aos", "fade-right");
          el.setAttribute("data-aos-delay", "900");
        });
        AOS.refresh();
      }
    };

    // Run the function on initial load
    handleAOS();

    // Add event listener for window resize
    window.addEventListener("resize", handleAOS);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleAOS);
    };
  }, []);

  useEffect(() => {
    // Target date and time for the pre-sale event in UTC
    const targetDateUTC = new Date("2024-07-15T00:00:00Z");// 00:00:00 EST is 04:00:00 UTC

    const updateTimer = () => {
      const nowUTC = new Date();
      const difference = targetDateUTC - nowUTC;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        // Countdown complete, set all values to 0
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timerId = setInterval(updateTimer, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, []);
  return (
    <div className="TTT">
      <section className="banner">
        <div className="container">
          <div className="Banner-heading">
            <h1>Tic Tac Trump</h1>
            <h4>(TTT)</h4>
          </div>
          <div className="row pt-5">
            <div className="col-lg-6">
              {" "}
              <img src={require("../Images/Banner.png")} />{" "}
            </div>
            <div className="col-lg-6 t120">
              <div className="banner-content">
                <h6 className="">
                  Folks, let’s talk <span>size — it matters!</span> Under my
                  watch, we’ll have Tic Tacs so big, you’ll need two hands to
                  pop one in your mouth!
                </h6>
                <div>
                  <button className="Box"> Make Tic Tacs Big Again !</button>{" "}
                  <br />
                  <button className="Primary-btn">
                    {" "}
                    <Link to="/buy"> Buy TTT</Link>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Rally">
        <div className="container">
          <div className="Banner-heading">
            <h2>Trump at a Rally</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 t120">
              <div className="banner-content px-4">
                <h6 className="x">
                  They've shrunk your Tic Tacs, folks. Next thing you know,
                  they'll be shrinking your dreams too.
                </h6>
                <h6 className="secondary my40">Not on my watch!</h6>
                <div>
                  <button className="Primary-btn">
                    {" "}
                    <Link to="/stake">Stake TTT</Link>{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {" "}
              <img
                src={require("../Images/Gif.gif")}
                className="d-block mx-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="Tonkenomics">
        <div className="container">
          <div className="Banner-heading">
            <h2 className="secondary">Tokenomics</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="banner-content">
                <p>
                  Welcome to the crypto-political carnival! Each trade throws a{" "}
                  <span className="text-red">1% tax</span> into Trump's campaign
                  hat. It's like buying a ticket to the wildest show in town!
                  Giddy up, folks!
                </p>
                <div></div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="banner-content">
                <div>
                  <img
                    src={require("../Images/Tokens.png")}
                    className="my-5"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 t120">
              <div className="banner-content">
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={require("../Images/Tokenarrow.png")}
                      className="mt-5 tokenarrow"
                      width="200px"
                    />

                    <button className="White-btn">
                      Reflecting Trump's <br />
                      birth year
                    </button>
                  </div>

                  <button className="Yellow-btn">
                    TOTAL SUPPLY <span>19,460,000 TTT</span>
                  </button>
                  <button className="Red-btn">
                    {" "}
                    <Link to="/buy">BUY TTT</Link>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">

            <div className="col-lg-9 my-5">
              <div className="contract-Address">
                <h6>Contract Address</h6>
                <p>{env.tokenAddres}</p>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="roadmap">
        <div className="container">
          <div className="Banner-heading">
            <h2 className="">Roadmap</h2>
          </div>
          <div
            className="row justify-content-center"
            data-aos="fade-right"
            data-aos-delay="900"
          >
            <div className="col-lg-3 d-flex align-items-center ">
              {/* <div className="phase1 ">
                <button>Phase 01</button>
              </div> */}
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <div className="phase1">
                <img
                  src={require("../Images/Right-arrow.png")}
                  className="arrowright"
                />
              </div>
              <div className="phase1">
                <img
                  src={require("../Images/bottom-arrow.png")}
                  className="arrowbottom"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-content phase01">
                <button>Phase 01</button>

                <div className="phase2">


                  <h5>Presale and Initial Launch</h5>
                  <ul>
                    <li>
                      <p>
                        Presale Event: Early token sale to engage initial
                        supporters and secure funding for development
                      </p>
                    </li>
                    <li>
                      <p>
                        Mainnet Launch on Binance Smart Chain: Official launch
                        on BSC for low fees and high transaction throughput
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center"
            data-aos="fade-left"
            data-aos-delay="900"
          >
            <div className="col-lg-6">
              <div className="banner-content phase01">
                <button>Phase 02</button>
                <div className="phase2">
                  <h5>Blockchain Expansion</h5>
                  <ul>
                    <li>
                      <p>
                        Solana Launch: Expand to Solana for its speed and
                        low-cost transactions, increasing accessibility
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 d-flex align-items-center">
              <div className="phase1 ">
                <img
                  src={require("../Images/left-arrow.png")}
                  className="arrowright"
                />
              </div>
              <div className="phase1">
                <img
                  src={require("../Images/top-arrow.png")}
                  className="arrowbottom"
                />
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              {/* <div className="phase1  ">
                <button>Phase 02</button>
              </div> */}
            </div>
          </div>

          <div
            className="row justify-content-center"
            data-aos="fade-right"
            data-aos-delay="900"
          >
            <div className="col-lg-3 d-flex align-items-center">
              {/* <div className="phase1">
                <button>Phase 03</button>
              </div> */}
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <div className="phase1">
                <img
                  src={require("../Images/Right-arrow.png")}
                  className="arrowright"
                />
              </div>
              <div className="phase1">
                <img
                  src={require("../Images/bottom-arrow.png")}
                  className="arrowbottom"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-content phase01">
                <button>Phase 03</button>
                <div className="phase2">
                  <h5>Community Engagement and Marketing</h5>
                  <ul>
                    <li>
                      <p>
                        Social Media Campaigns: Boost visibility through
                        targeted social media efforts
                      </p>
                    </li>
                    <li>
                      <p>
                        Influencer Collaborations: Partner with influencers to
                        extend reach and credibility
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center"
            data-aos="fade-left"
            data-aos-delay="900"
          >
            <div className="col-lg-6">
              <div className="banner-content phase01">
                <button>Phase 04</button>
                <div className="phase2">
                  <h5>CEX Listings</h5>
                  <ul>
                    <li>
                      <p>
                        List the token on major centralized exchanges to increase liquidity and trading volume.


                      </p>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <div className="phase1 ">
                <img
                  src={require("../Images/left-arrow.png")}
                  className="arrowright"
                />
              </div>
              <div className="phase1">
                <img
                  src={require("../Images/top-arrow.png")}
                  className="arrowbottom"
                />
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              {/* <div className="phase1">
                <button>Phase 04</button>
              </div> */}
            </div>
          </div>

          <div
            className="row justify-content-center"
            data-aos="fade-right"
            data-aos-delay="900"
          >
            <div className="col-lg-3 d-flex align-items-center">
              {/* <div className="phase1">
                <button>Phase 05</button>
              </div> */}
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <div className="phase1">
                <img
                  src={require("../Images/Right-arrow.png")}
                  className="arrowright"
                />
              </div>
              <div className="phase1">
                <img
                  src={require("../Images/bottom-arrow.png")}
                  className="arrowbottom"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-content phase01">
                <button>Phase 05</button>
                <div className="phase2">
                  <h5>Feature Enhancements and Governance</h5>
                  <ul>
                    <li>
                      <p>
                        DAO Implementation: Set up a DAO for community-driven
                        governance and decision-making.
                      </p>
                    </li>
                    {/* <li>
                      <p>
                        Staking Programs: Introduce staking to incentivize
                        long-term holding and stabilize the coin’s economy.
                      </p>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center"
            data-aos="fade-left"
            data-aos-delay="900"
          >
            <div className="col-lg-6">
              <div className="banner-content phase01">
                <button>Phase 06</button>
                <div className="phase2">
                  <h5>Compliance and Future Planning</h5>
                  <ul>
                    <li>
                      <p>
                        Regulatory Compliance Checks: Regular updates to comply
                        with evolving cryptocurrency regulations.
                      </p>
                    </li>
                    <li>
                      <p>
                        Anniversary Events: Celebrate key milestones with
                        community events and promotions.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <div className="phase1 ">
                <img
                  src={require("../Images/left-arrow.png")}
                  className="arrowright"
                />
              </div>
              <div className="phase1">
                <img
                  src={require("../Images/top-arrow.png")}
                  className="arrowbottom"
                />
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              {/* <div className="phase1">
                <button>Phase 04</button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="Pre-sale">
        <div className="container">
          <div className="Banner-heading">
            <h2 className="secondary">Pre-sale Event</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="banner-content">
                <p>
                  Grab 'Em by the Coins:{" "}
                  <span className="text-red">Pre-Sale</span> Bonanza !
                </p>
                <div className="countdown">
                  <div className="day">
                    <p> {timeLeft.days}</p> <span>{timeLeft.days == 1 ? "Day" : "Days"}</span>
                  </div>
                  <div className="hour">
                    <p> {timeLeft.hours}</p> <span>{timeLeft.days == 1 ? "Hour" : "Hours"}</span>
                  </div>
                  <div className="min">
                    <p> {timeLeft.minutes}</p> <span>{timeLeft.days == 1 ? "Minute" : "Minutes"}</span>
                  </div>
                  <div className="sec">
                    <p> {timeLeft.seconds}</p> <span>{timeLeft.days == 1 ? "Seconds" : "Seconds"}</span>
                  </div>
                </div>

                <button className="d-block mx-auto">
                  {" "}
                  <Link to="/buy">BUY TTT Here</Link>{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="banner-content">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="socials">
        <div className="container">
          <div className="Banner-heading">
            <h2>Socials</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {" "}
              <img
                src={require("../Images/social.png")}
                className="social-trumb"
              />{" "}
            </div>
            <div className="col-lg-6">
              <div className="social-media">
                <Link to="https://x.com/TicTacTrumpCoin" target="_blank"><img src={require("../Images/twitter.png")} /></Link>
               <Link to="https://t.me/tictactrump" target="_blank"><img src={require("../Images/telegram.png")} /></Link> 
              </div>
              <div className="banner-content">
                <p>
                  Tic Tac Trump ( TTT ) coin is a cryptocurrency combined with a
                  movement that bridges the gap between digital finance and
                  political support. By participating in this initiative, you're
                  not just investing; you're actively supporting Donald J.
                  Trump's vision for America. Join us as we pave the way for a
                  new era of political engagement through cryptocurrency.
                </p>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer">
        <div className="footer-content">
          <p>Copyright by TTT. All rights reserved</p>
        </div>
      </section>
    </div>
  );
}

export default Home;
