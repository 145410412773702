import React, { useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import "semantic-ui-css/semantic.min.css";
import { Dropdown } from "semantic-ui-react";
import Header from "./Header";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";
import Web3 from "web3";
import BNBforTokenABI from "./BNBforTokenABI.json";
import SwapBNBforTokenABI from "./SwapBNBforTokenABI.json";
import StakeNEWBEP20ABI from './stake.json';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import env from "../core/service/envconfig";
import moment from "moment"
import { toast } from "react-toastify";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import { Loading } from "notiflix/build/notiflix-loading-aio";

export default function Buy() {


const projectId = "0b8444fa79de9a3821c5410564de263f";

  const mainnet = {
    chainId: 0x38, //Mainnet
    name: "Binance Smart Chain ", //Mainnet
    currency: "BNB", //Mainnet
    explorerUrl: "https://bscscan.com/",  //Mainnet
    rpcUrl: "https://bsc-dataseed1.binance.org/", //Mainnet

    // chainId: 0x61,
    // explorerUrl: "https://testnet.bscscan.com/",
    // rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // name: "Binance Smart Chain Testnet",
    // currency: "tBNB",
    
  };
  const chains = [mainnet];
  const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Solana Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.mywebsite.com/"],
  };
  const ethersConfig = defaultConfig({
    metadata,
    chains,
    projectId,
  });
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
  });

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();

  const [allsell, setallsell, allsellref] = useStateRef([]);
  const [selectedsell, setSelectedsell] = useState(null);
  const [WalletStatus, setWalletStatus] = useState(false);


  useEffect(() => {
    const Sell = [
      {
        value: "TTT",
        key: "TTT",
        text: "TTT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/dh3iko4f6/image/upload/v1718382339/Logo1_wuhkez.png",
        },
      },
    ];
    setallsell(Sell);
    setSelectedsell(Sell[0].value);
    const account = localStorage.getItem('userAddress');

    if (account == "" ||
      account == null ||
      account == undefined ||
      account == "NaN" ||
      account == NaN
    ) {
      setWalletStatus(false)
    } else {
      setWalletStatus(true)

    }
  }, [setallsell]);

  const [mytrades, setmytrades] = useState("home");

  const mytradesfunction = (newValue) => {
    setmytrades(newValue);
  };

  const [activeTab, setactiveTab] = useState(0);
  const [duration, setduration] = useState(0);
  const [apy_calc, setapy_calc] = useState(0);
  const [futureDate, setFutureDate] = useState(null);
  const [currentDate, setcurrentDate] = useState(null);
  const [Apy, setApy] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [tttTokenBalance, settttTokenBalance, tttTokenBalanceref] = useStateRef(0);
  const [usdtPrice, setUsdtPrice, usdtPriceref] = useStateRef(0)
  const [StakeErrorMessage, setStakeErrorMessage, StakeErrorMessageref] = useStateRef("")
  const [stakeError, setStakeError, StakeErrorref] = useStateRef(false)
  const [dailyReward, setdailyReward, dailyRewardref] = useStateRef(false)
  const [monthlyReward, setmonthlyReward, monthlyRewardref] = useStateRef(false)
  const [stakedBalance, setStakedBalance, StakedBalanceref] = useStateRef(0)
  const [StakeAmount, setStakeAmount, stakeAmountref] = useStateRef();
  const [claimAmount, setclaimAmount, claimAmountref] = useStateRef();
  const [availableClaimAmount, setavailableClaimAmount, availableClaimAmountref] = useStateRef(0);
  const [Claimmsg, setClaimmsg, Claimmsgref] = useStateRef("")
  const [claimError, setclaimError, claimErrorref] = useStateRef(false)
  const [finalreward, setfinalReward] = useState(0);
  const [claimbuttonLoader, setclaimbuttonLoader] = useState(false);
  const [userAccount, setUserAccount] = useState(null);
  const [bnbBalance, setBnbBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [web3, setWeb3] = useState(null);
  const [toastId, setToastId] = useState(null);
  const [periodvalue, setPeriod] = useState(3);

  const [Staking_history, setStaking_history, Staking_historyref] = useStateRef([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    Loading.hourglass("Connecting...", {
      messageColor: "#fff",
      svgColor: "#fff",
      svgSize: "100px",
      backgroundColor: "rgb(27 138 59 / 68%)",
    });
    initializeWeb3();
    const intervalId = setInterval(initializeWeb3, 2000);
    Loading.remove()
    return () => clearInterval(intervalId);

  }, [0]);

  const initializeWeb3 = async () => {

    // console.log("Time Out ");
    const userAddress = localStorage.getItem("userAddress");
    const Bnbbalance = localStorage.getItem("Balance");

    const TTTbalance = localStorage.getItem("TTTBalance");

    settttTokenBalance(TTTbalance)

    setUserAccount(userAddress);
    setBnbBalance(Bnbbalance);

    if (userAddress != null) {
      setWalletStatus(true)
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      const contractInstance = new web3Instance.eth.Contract(
        SwapBNBforTokenABI,
        env.BuyAddress
      );
      const TokenContract = new web3Instance.eth.Contract(
        BNBforTokenABI,
        env.tokenAddres
      );
      const tokenBalance = await TokenContract.methods
        .balanceOf(userAddress)
        .call();
      settttTokenBalance(Number(tokenBalance) / 1000000);
    } else {
      // console.log("kniknnjnjnmjbm b");
      settttTokenBalance(0);
      setWalletStatus(false)
      // setTokenBalance(0);
    }
  };


  
  // useEffect(() => {
  //   getTokenBalance();
  // }, [])

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formatter_interest = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 5,
    maximumFractionDigits: 5,
  });
  const getTokenBalance = async () => {
    try {
      const userAddress = localStorage.getItem('userAddress');
      const web3Instance = new Web3(window.ethereum);
      const Tokencontarct = new web3Instance.eth.Contract(BNBforTokenABI, env.tokenAddres);
      const tokenBalance = await Tokencontarct.methods.balanceOf(userAddress).call();
      settttTokenBalance(Number(tokenBalance) / 1000000)
    } catch (err) {
      // console.log(err, "err of token balance")
    };
  };

  const getTokenBalance1 = async () => {
    try {
      const userAddress = localStorage.getItem('userAddress');
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

        const Tokencontarct = new Contract(
          env.tokenAddres,
          BNBforTokenABI,
          bnbProvider
        );
        const tokenBalance = await Tokencontarct.balanceOf(userAddress);
      settttTokenBalance(Number(tokenBalance) / 1000000)
    } catch (err) {
      // console.log(err, "err of token balance")
    };
  };
  // const showSuccessToast = (message) => {
  //   if (toastId) {
  //     toast.dismiss(toastId);
  //   }
  //   const newToastId = toast.success(message);
  //   setToastId(newToastId);
  // };

  // const showErrorToast = (message) => {
  //   if (toastId) {
  //     toast.dismiss(toastId);
  //   }
  //   const newToastId = toast.error(message);
  //   setToastId(newToastId);
  // };


  const stake_change = (e) => {
    try {
      setStakeAmount(e.target.value)
      if(WalletStatus==false){
        if (/^0{5,}/.test(e.target.value)) {
          setStakeError(true)
          setStakeAmount("");
          e.target.value = "";
          setStakeErrorMessage("Enter Valid Amount !")
        } else if (
          e.target.value.length == "00.0" ||
          e.target.value.length == "000.0" ||
          e.target.value.length == "0000.0"
        ) {
          setStakeError(true)
          setStakeAmount("");
          e.target.value = "";
          setStakeErrorMessage("Enter Valid Amount !")
        }
        if (!e.target.value || e.target.value == "" || e.target.value == null || e.target.value == undefined || e.target.value == NaN) {
          setStakeError(true)
          setStakeErrorMessage("Enter Valid Amount !")
        }
        if (!duration && duration == 0) {
          setStakeAmount("");
          setStakeError(true)
          setStakeErrorMessage("Choose staking Duration !")
        } else if (stakeAmountref.current.length > 9) {
          setStakeError(true)
          setStakeAmount("");
          setStakeErrorMessage("Enter Valid Amount !")
        } else {
          setStakeError(false)
          setStakeErrorMessage("")
  
          const interestRate = Number(apy_calc) / 100;
          const totalAmount = Number(stakeAmountref.current) * Number(interestRate);
          const perDayReward = Number(totalAmount) / Number(duration);
  
  
          const finalRewards = perDayReward * +duration;
          setfinalReward(finalRewards);
  
          if (isNaN(perDayReward) == true) {
            setdailyReward(0);
          } else {
            setdailyReward(
              perDayReward == "" ||
                perDayReward == null ||
                perDayReward == undefined ||
                perDayReward == "NaN" ||
                perDayReward == NaN
                ? 0
                : perDayReward
            );
          }
          const monthlyreward = perDayReward * +duration;
          if (isNaN(monthlyreward) == true) {
            setdailyReward(0);
          } else {
            setmonthlyReward(
              monthlyreward == "" ||
                monthlyreward == null ||
                monthlyreward == undefined ||
                monthlyreward == "NaN"
                ? 0
                : +monthlyreward
            );
          }
        }
      }
      else{
      if (/^0{5,}/.test(e.target.value)) {
        setStakeError(true)
        setStakeAmount("");
        e.target.value = "";
        setStakeErrorMessage("Enter Valid Amount !")
      } else if (
        e.target.value.length == "00.0" ||
        e.target.value.length == "000.0" ||
        e.target.value.length == "0000.0"
      ) {
        setStakeError(true)
        setStakeAmount("");
        e.target.value = "";
        setStakeErrorMessage("Enter Valid Amount !")
      }
      if (!e.target.value || e.target.value == "" || e.target.value == null || e.target.value == undefined || e.target.value == NaN) {
        setStakeError(true)
        setStakeErrorMessage("Enter Valid Amount !")
      }
      if (!duration && duration == 0) {
        setStakeAmount("");
        setStakeError(true)
        setStakeErrorMessage("Choose staking Duration !")
      } else if (stakeAmountref.current.length > 9) {
        setStakeError(true)
        setStakeAmount("");
        setStakeErrorMessage("Enter Valid Amount !")
      } else if (stakeAmountref.current > tttTokenBalanceref.current) {
        // setStakeError(true)
        // setStakeErrorMessage("Insufficient Token Balance !")

        const interestRate = Number(apy_calc) / 100;
        const totalAmount = Number(stakeAmountref.current) * Number(interestRate);
        const perDayReward = Number(totalAmount) / Number(duration);


        const finalRewards = perDayReward * +duration;
        setfinalReward(finalRewards);

        if (isNaN(perDayReward) == true) {
          setdailyReward(0);
        } else {
          setdailyReward(
            perDayReward == "" ||
              perDayReward == null ||
              perDayReward == undefined ||
              perDayReward == "NaN" ||
              perDayReward == NaN
              ? 0
              : perDayReward
          );
        }
        const monthlyreward = perDayReward * +duration;
        if (isNaN(monthlyreward) == true) {
          setdailyReward(0);
        } else {
          setmonthlyReward(
            monthlyreward == "" ||
              monthlyreward == null ||
              monthlyreward == undefined ||
              monthlyreward == "NaN"
              ? 0
              : +monthlyreward
          );
        }
      } else {
        setStakeError(false)
        setStakeErrorMessage("")
        
        const interestRate = Number(apy_calc) / 100;
        const totalAmount = Number(stakeAmountref.current) * Number(interestRate);
        const perDayReward = Number(totalAmount) / Number(duration);


        const finalRewards = perDayReward * +duration;
        setfinalReward(finalRewards);

        if (isNaN(perDayReward) == true) {
          setdailyReward(0);
        } else {
          setdailyReward(
            perDayReward == "" ||
              perDayReward == null ||
              perDayReward == undefined ||
              perDayReward == "NaN" ||
              perDayReward == NaN
              ? 0
              : perDayReward
          );
        }
        const monthlyreward = perDayReward * +duration;
        if (isNaN(monthlyreward) == true) {
          setdailyReward(0);
        } else {
          setmonthlyReward(
            monthlyreward == "" ||
              monthlyreward == null ||
              monthlyreward == undefined ||
              monthlyreward == "NaN"
              ? 0
              : +monthlyreward
          );
        }
      }
    }
    } catch (err) {
      // console.log("errrr", err)
    };
  };
  const chooseDuration = (duration, apy, days) => {
    try {
      setStakeAmount("");
      setStakeError(false)
      setStakeErrorMessage("")
      setactiveTab(apy);
      setduration(duration);
      setStakeAmount("");
      setdailyReward(0);
      setmonthlyReward(0);
     
      var calc_apy = 360 / +days;
      var final_apy = +calc_apy * +apy;
      setApy(final_apy);
      setapy_calc(apy);

      const currentDates = new Date();
      setcurrentDate(currentDates);
      const futureDate = new Date(
        currentDates.getTime() + duration * 24 * 60 * 60 * 1000
      );
      setFutureDate(
        futureDate.toDateString() + " " + futureDate.toLocaleTimeString()
      );

    } catch (err) {
      // console.log(err, "errfor duration choose")
    }
  };

  const buytype = () => {
    const wallettype = localStorage.getItem("wallettype");

    if (wallettype == "metamask") {
      approve_stake();
    } else {
      approve_stake1();
    }
  };


  const approve_stake = async () => {
    try {
      if (stakeAmountref.current == 0) {
        setStakeError(true)
        setStakeErrorMessage("Enter Valid Amount !")
      } else {
        setButtonLoader(true);
        Loading.hourglass("Connecting...", {
          messageColor: "#fff",
          svgColor: "#fff",
          svgSize: "100px",
          backgroundColor: "rgb(27 138 59 / 68%)",
        });
        const account = localStorage.getItem('userAddress');
        const web3 = new Web3(window.ethereum);
        const gasPrice = await web3.eth.getGasPrice();
        const tokenContract = new web3.eth.Contract(BNBforTokenABI, env.tokenAddres); // Assume ABI and tokenAddress are defined
        const stakingContract = new web3.eth.Contract(StakeNEWBEP20ABI, env.stakeContractAddressNew); // Assume StakingABI and env.stakeContractAddressNew are defined
        const percentage = env.percentage / 100;
        const fees_result = stakeAmountref.current * percentage;
        const remaining_amount = stakeAmountref.current - fees_result;
       
        await tokenContract.methods.approve(env.stakeContractAddressNew, stakeAmountref.current * 1000000,).send({ from: account }).then(async res => {
          // Stake tokens
          await stakingContract.methods.stakeTokens(web3.utils.toWei(stakeAmountref.current.toString(), "mwei"),  web3.utils.toWei(fees_result.toString(), "mwei") , web3.utils.toWei(remaining_amount.toString(), "mwei")).send({
            from: account, gas: 2000000, gasPrice: gasPrice,
          }).then(async res => {
            var stake_values = {
              staking_amount: stakeAmountref.current,
              interest_rate: finalreward,
              staking_start_date: currentDate,
              contract_address: account,
              staking_end_date: futureDate,
              transaction_id: res.signature,
            };
            var data = {
              apiUrl: apiService.staking,
              payload: stake_values,
            };
            // StakeNowcrypto();
            var resp = await postMethod(data);
            if (resp) {
              toast.success('Tokens staked successfully');
              setStakeAmount("");
              setactiveTab(0)
              setButtonLoader(false);
              Loading.remove();
              get_stakehistory();

            }
          }).catch(err => {
            setButtonLoader(false);
            Loading.remove();

            setStakeAmount("");
            setactiveTab(0)
            console.log(err)
            toast.error('Transaction Failed !');
          })
        }).catch(err => {
          setButtonLoader(false);
          Loading.remove();
          setStakeAmount("");
          setactiveTab(0)
          console.log(err)
          toast.error('Transaction Failed !');
        });
        getTokenBalance();
      }
    } catch (error) {
      console.log(error, "errfor staking")
    }


  }

  const approve_stake1 = async () => {
    try {
      if (stakeAmountref.current == 0) {
        setStakeError(true)
        setStakeErrorMessage("Enter Valid Amount !")
      } else {
        setButtonLoader(true);
        Loading.hourglass("Connecting...", {
          messageColor: "#fff",
          svgColor: "#fff",
          svgSize: "100px",
          backgroundColor: "rgb(27 138 59 / 68%)",
        });
        const account = localStorage.getItem('userAddress');
        

        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();

        const tokenContract = new Contract(
          env.tokenAddres,
          BNBforTokenABI,
          signer
        );

        const stakingContract = new Contract(
          env.stakeContractAddressNew,
          StakeNEWBEP20ABI,
          signer
        );

        const percentage = env.percentage / 100;
        const fees_result = stakeAmountref.current * percentage;
        const remaining_amount = stakeAmountref.current - fees_result;
        const fees = web3.utils.toWei(stakeAmountref.current.toString(), "mwei");
        const feesres = web3.utils.toWei(fees_result.toString(), "mwei");
        const remaining_ = web3.utils.toWei(remaining_amount.toString(), "mwei");
        const gasPrice = (await provider.getFeeData()).gasPrice;



        
        await tokenContract.approve(env.stakeContractAddressNew, stakeAmountref.current * 1000000, {gasLimit : 1000000, gasPrice : gasPrice, } ).then(async res => {

          await stakingContract.stakeTokens(fees, feesres  , remaining_, {gasLimit : 1000000, gasPrice : gasPrice}).then(async res => {

            var stake_values = {
              staking_amount: stakeAmountref.current,
              interest_rate: finalreward,
              staking_start_date: currentDate,
              contract_address: account,
              staking_end_date: futureDate,
              transaction_id: res.hash,
            };
            var data = {
              apiUrl: apiService.staking,
              payload: stake_values,
            };
            // StakeNowcrypto();
            var resp = await postMethod(data);
            if (resp) {
              toast.success('Tokens staked successfully');
              setStakeAmount("");
              setactiveTab(0)
              setButtonLoader(false);
              Loading.remove();
              get_stakehistory();

            }
          }).catch(err => {
            setButtonLoader(false);
            Loading.remove();

            setStakeAmount("");
            setactiveTab(0)
            console.log(err)
            toast.error('Transaction Failed !');
          })
        }).catch(err => {
          setButtonLoader(false);
          Loading.remove();
          setStakeAmount("");
          setactiveTab(0)
          console.log(err)
          toast.error('Transaction Failed !');
        });
        getTokenBalance1();
      }
    } catch (error) {
      console.log(error, "errfor staking")
    }
  }

  useEffect(() => {
    get_stakehistory();


  }, [])


  const get_stakehistory = async () => {
    try {
      const account = localStorage.getItem('userAddress');

      var obj = {
        contract_address: account,
      };
      var data = {
        apiUrl: apiService.get_stakehistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      setStaking_history(resp.stakehistory);
    } catch (error) {
      console.error('Error fetching claim details:', error);
    }
  }

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => prevPage + 1);

  };

  var itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Staking_historyref.current.slice(indexOfFirstItem, indexOfLastItem);



  const MaxChoose = () => {
    try {
      if (!duration && duration == 0) {
        setStakeAmount("");
        setStakeError(true)
        setStakeErrorMessage("Choose staking Duration !")
      } else {
        // var balance = formatter_interest.format(tttTokenBalanceref.current)
        let truncatedNumber = tttTokenBalanceref.current.toString().split('.')[1].substring(0, 4);
        let balance = `${tttTokenBalanceref.current.toString().split('.')[0]}.${truncatedNumber}`;
        // setStakeAmount(balance.replace(/,/g, ''));
        console.log(balance, "balance")
        setStakeAmount(balance);
        const interestRate = Number(apy_calc) / 100;
        const totalAmount = Number(stakeAmountref.current) * Number(interestRate);
        const perDayReward = Number(totalAmount) / Number(duration);


        const finalRewards = perDayReward * +duration;
        setfinalReward(finalRewards);

        if (isNaN(perDayReward) == true) {
          setdailyReward(0);
        } else {
          setdailyReward(
            perDayReward == "" ||
              perDayReward == null ||
              perDayReward == undefined ||
              perDayReward == "NaN" ||
              perDayReward == NaN
              ? 0
              : perDayReward
          );
        }
        const monthlyreward = perDayReward * +duration;
        if (isNaN(monthlyreward) == true) {
          setdailyReward(0);
        } else {
          setmonthlyReward(
            monthlyreward == "" ||
              monthlyreward == null ||
              monthlyreward == undefined ||
              monthlyreward == "NaN"
              ? 0
              : +monthlyreward
          );
        }
      }

    } catch (err) {
      // console.error('Error fetching staked balance:', err);
    }
  }

  useEffect(() => {
    const get_claim = async () => {
      try {
        const account = localStorage.getItem('userAddress');

        var obj = {
          contract_address: account,
        };
        var data = {
          apiUrl: apiService.available_stakeplans,
          payload: obj,
        };
        var resp = await postMethod(data);
        setavailableClaimAmount(resp.available_stakeplans);
      } catch (error) {
        console.error('Error fetching claim details:', error);
      }
    }
    const intervalId = setInterval(get_claim, 5000);

    return () => clearInterval(intervalId);
  }, [])


  const claim_change = async (e) => {
    try {
        if (
          e.target.value == "0000000000" ||
          e.target.value == "0.00000000" ||
          e.target.value.length == 11
        ) {
          e.target.value = "";
          setclaimAmount("");
        }
        if (
          e.target.value.length == "00.0" ||
          e.target.value.length == "000.0" ||
          e.target.value.length == "0000.0"
        ) {
          e.target.value = "";
          setclaimAmount("");
        }
        if (availableClaimAmountref.current == 0) {
          setclaimError(true);
          setClaimmsg("");
        } else if (e.target.value > availableClaimAmountref.current) {
          setclaimError(true);
          setClaimmsg("Your amount is exceed your rewards!");
        } else if (
          Number(e.target.value) > availableClaimAmountref.current ||
          availableClaimAmountref.current == 0
        ) {
          setclaimError(true);
          setClaimmsg("Your amount is exceed your rewards!");
        } else if (e.target.value.length <= 9) {
          setclaimAmount(e.target.value);
          setclaimError(false);
        }
      // }
     
    } catch (error) {
      // console.log("claim change err", error)
    }
  }

  const claimtype = () => {
    const wallettype = localStorage.getItem("wallettype");

    if (wallettype == "metamask") {
      approve_claim();
    } else {
      approve_claim1();
    }
  };
  const approve_claim = async (e) => {
    try {
      setclaimbuttonLoader(true);
      Loading.hourglass("Processing...", {
        messageColor: "#fff",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });
      const account = localStorage.getItem('userAddress');
      const web3 = new Web3(window.ethereum);
      const gasPrice = await web3.eth.getGasPrice();
      const tokenContract = new web3.eth.Contract(BNBforTokenABI, env.tokenAddres); // Assume ABI and tokenAddress are defined
      const stakingContract = new web3.eth.Contract(StakeNEWBEP20ABI, env.stakeContractAddressNew);
      // const contract = new web3.eth.Contract(StakeNEWBEP20ABI, env.stakeContractAddressNew);
      try {
        await stakingContract.methods.unstakeTokens(claimAmountref.current * 1000000).send({ from: account, gas: 1000000, gasPrice: gasPrice, }).then(async res => {
          var claim_obj = {
            amount: claimAmountref.current,
            contract_address: account
          }
          var data = {
            apiUrl: apiService.claim_Stake,
            payload: claim_obj,
          };
          var resp = await postMethod(data);
          if (resp) {
            setavailableClaimAmount(resp.available_stakeplans)
            toast.success(resp.Message);
            setclaimAmount("");
            setclaimbuttonLoader(false);
            Loading.remove();
          }
        }).catch(err => {
          // console.log(err)
          setclaimbuttonLoader(false);
          Loading.remove();
        })
      } catch (error) {
        toast.error("Transaction Failed !");
        // console.error(error);
        setclaimbuttonLoader(false);
        Loading.remove();
      }
      getTokenBalance();
    } catch (error) {
      // console.error('Error approve_claim:', error);
      setclaimbuttonLoader(false);
    }
  }

  const approve_claim1 = async (e) => {
    try {
      setclaimbuttonLoader(true);
      Loading.hourglass("Processing...", {
        messageColor: "#fff",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });
      const account = localStorage.getItem('userAddress');

      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const stakingContract = new Contract(
        env.stakeContractAddressNew,
        StakeNEWBEP20ABI,
        signer
      );
      const gasPrice = (await provider.getFeeData()).gasPrice;


      try {
        await stakingContract.unstakeTokens(claimAmountref.current * 1000000, {gasLimit : 1000000, gasPrice : gasPrice,}).then(async res => {
          var claim_obj = {
            amount: claimAmountref.current,
            contract_address: account
          }
          var data = {
            apiUrl: apiService.claim_Stake,
            payload: claim_obj,
          };
          var resp = await postMethod(data);
          if (resp) {
            setavailableClaimAmount(resp.available_stakeplans)
            toast.success(resp.Message);
            setclaimAmount("");
            setclaimbuttonLoader(false);
            Loading.remove();
          }
        }).catch(err => {
          // console.log(err)
          setclaimbuttonLoader(false);
          Loading.remove();
        })
      } catch (error) {
        toast.error("Transaction Failed !");
        // console.error(error);
        setclaimbuttonLoader(false);
        Loading.remove();
      }
      getTokenBalance1();
    } catch (error) {
      // console.error('Error approve_claim:', error);
      setclaimbuttonLoader(false);
    }
  }

  const ConnectWallet = async (e) => {  
    handlepopClose()
    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });

      if (!checkForPhantomWallet()) {

      if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const bscNetwork = {
            chainId: '0x38',
            chainName: 'Binance Smart Chain',
            rpcUrls: [env.rpc_url],
            nativeCurrency: {
              name: 'BNB',
              symbol: 'BNB',
              decimals: 18,
            },
            blockExplorerUrls: ['https://bscscan.com'],
          };

          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: bscNetwork.chainId }],
          });

          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);

          const accounts = await web3Instance.eth.getAccounts();
          setUserAccount(accounts[0]);

          // Store address in local storage
          localStorage.setItem('userAddress', accounts[0]);

          const weiBalance = await web3Instance.eth.getBalance(accounts[0]);
          const ethBalance = web3Instance.utils.fromWei(weiBalance, 'ether');
          setBnbBalance(ethBalance);
          localStorage.setItem('Balance', ethBalance);
          Loading.remove();
          setWalletStatus(true);
          getTokenBalance();
        } catch (error) {
          // console.error(error);
          Loading.remove();
        }
      } else {
        toast.error('MetaMask not found');
        Loading.remove();
      }
    }else{
      Loading.remove();
    }
    } catch (error) {
      // console.log(error, "errfor connect wallet")
      Loading.remove();
    }
  }

  const handleWalletConnect = async (e) => {
    handlepopClose();
    await open();
  
    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      setUserAccount(address);
      const balance = await provider.getBalance(address);
      localStorage.setItem("userAddress", address);
      const ethBalance = parseInt(balance) / 1000000000000000000;
      setBnbBalance(ethBalance);
      localStorage.setItem("Balance", ethBalance);
  
      Loading.remove();
      setWalletStatus(true);
      getTokenBalance1();
    } catch (error) {
      // console.log(error, "errfor connect wallet")
      Loading.remove();
    }
  };
  

  const calculateAPY = (roi, time) => {
    // Calculate the APY based on ROI and number of compounding periods in a year
    let periods = (time == 24) ? 1 : 12 / time;
    let apy = Math.pow((1 + roi / periods), periods) - 1;
    apy = apy * 100;
    return parseFloat(apy).toFixed(2);
   }

   const calculateTotalInterest = (principal, apy, months, periodsInYear) => {
    let interest = principal * (apy / 100);
    return interest.toFixed(5); // To get the result up to 2 decimal places
   }

//    function calculateTotalInterest(principal, apy, months, periodsInYear) {
//     // Convert APY to an effective interest rate for the specific duration
//     var apy_calc = apy / 100;
//     let effectiveRate = Math.pow(1 + apy_calc, months / periodsInYear) - 1;
//     // Calculate the total interest for the given principal and effective rate
//     let interest = principal * effectiveRate;
//     return interest.toFixed(5); // To get the result up to 2 decimal places
// }
  function checkForPhantomWallet() {
   
    if (typeof window.solana !== 'undefined' && window.solana.isPhantom) {
        // console.log('Phantom Wallet detected. Please disable it for MetaMask testing.');
        alert('Phantom Wallet is detected. Please disable Phantom Wallet extension for  Use  MetaMask .');
        return true;
    }else{
      return false;
    }
}

function truncateToDecimals(num, dec) {
  const calcDec = Math.pow(10, dec);
  return Math.round(num * calcDec) / calcDec;
  }

  const [open1, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlepopClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Header />
      <div className="Buy-page">
        <section className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="buy-card my-5">
                <div className="form_seldect__pair pt-0 tradeform">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        onClick={() => mytradesfunction("home")}
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Stake
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="profile-tab"
                        onClick={() => mytradesfunction("menu2")}
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Claim
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="history-tab"
                        onClick={() => mytradesfunction("menu3")}
                        data-bs-toggle="tab"
                        data-bs-target="#history"
                        type="button"
                        role="tab"
                        aria-controls="history"
                        aria-selected="false"
                      >
                        History
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content pair_details" id="myTabContent">
                    <div
                      id="home"
                      role="tabpanel"
                      className={`tab-pane fade ${mytrades === "home" ? "show active" : ""
                        }`}
                      aria-labelledby="home-tab"
                    >
                      <div className="Buy-section">
                        <div className="d-flex align-items-center justify-content-around gap-1">
                          <div
                            className={
                              activeTab == "8"
                                ? "stake-top-card_active"
                                : "stake-top-card"
                            }
                            onClick={() => chooseDuration("90", "8", "90")}
                          >
                            <h5>32%</h5>
                            <span>3 Months</span>
                          </div>
                          <div className={
                            activeTab == "18"
                              ? "stake-top-card_active"
                              : "stake-top-card"
                          }
                            onClick={() => chooseDuration("150", "18", "150")}>
                            <h5>43.2%</h5>
                            <span>5 Months</span>
                          </div>
                          <div
                            className={
                              activeTab == "60"
                                ? "stake-top-card_active"
                                : "stake-top-card"
                            }
                            onClick={() => chooseDuration("360", "60", "360")}>
                            <h5>60%</h5>
                            <span>1 Year</span>
                          </div>
                          <div
                            className={
                              activeTab == "150"
                                ? "stake-top-card_active"
                                : "stake-top-card"
                            }
                            onClick={() => chooseDuration("720", "150", "720")}>
                            <h5>150%</h5>
                            <span>2 Years</span>
                          </div>
                        </div>
                      </div>
                      <div className="Buy-section">
                        <div className="betcen">
                          <div>
                            <p>Amount</p>
                          </div>
                          <div className="text-end">
                            {" "}
                            <p className="text-sec cursor" onClick={MaxChoose}>Max</p>
                          </div>
                        </div>
                        <div className="betcen">
                          <div>
                            <h4>

                              <h4>
                                <img
                                  src={"https://res.cloudinary.com/dh3iko4f6/image/upload/v1718382339/Logo1_wuhkez.png"}
                                  width="40px"
                                />{" "}
                                TTT
                              </h4>
                            </h4>
                          </div>
                          <div className="text-end">
                            {" "}
                            {/* <h4>00.00</h4> */}
                            <h4><input
                              type="number"
                              placeholder="Enter Amount"
                              value={stakeAmountref.current}
                              onChange={stake_change}
                              onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            /></h4>
                            {/* <h4>{formatter.format(tttTokenBalanceref.current)}</h4> */}
                          </div>
                        </div>
                        <div className="betcen">
                          <div>
                            <p>TIC TAC TRUMP</p>
                          </div>
                          <div className="text-end">
                            {" "}
                            {/* <p>~$00.00</p> */}
                            {" "}
                            <p>
                              {/* {tttTokenBalanceref.current} */}
                              {tttTokenBalanceref.current == null ||
                                tttTokenBalanceref.current == "NaN" ||
                                tttTokenBalanceref.current == undefined ||
                                tttTokenBalanceref.current == ""
                                ? 0
                                :tttTokenBalanceref.current}
                                {/* // : formatter_interest.format(tttTokenBalanceref.current)}{" "} */}
                            </p>
                            {/* <p>{formatter.format(usdtPriceref.current)}</p> */}
                          </div>
                        </div>
                        <h5 className="danger">
                          {StakeErrorref.current == true ? StakeErrorMessageref.current : ""}
                        </h5>
                      </div>
                      {
                        WalletStatus == false ?
                          <button className="btn" onClick={handleClickOpen}>Connect Wallet</button>
                          :
                          buttonLoader == true ?
                            <button className="btn">Loading...</button>
                            : !stakeAmountref.current || stakeAmountref.current == "" || stakeAmountref.current == 0 ?
                              <button className="btn">Stake Now</button> : <button className="btn" onClick={buytype}>Stake Now</button>

                      }
                      {/* <div>
  <Header data="name"/>
</div> */}
                      <div className="Buy-slip">
                        <div className="Buy-slip1">
                          <div className="">
                            <p>Current APY%</p>
                          </div>
                          <div className="text-end">
                            <p className="text-pri">  {Apy == null ||
                              Apy == "" ||
                              Apy == undefined ||
                              Apy == NaN
                              ? "0"
                              : truncateToDecimals(Apy,1)}{" "}%</p>
                          </div>
                        </div>
                        <div className="Buy-slip1">
                          <div className="">
                            <p>Stake Date</p>
                          </div>
                          <div className="text-end">
                            <p>
                              {currentDate == null ||
                                currentDate == "" ||
                                currentDate == undefined ||
                                currentDate == NaN
                                ? "--"
                                : moment(new Date(currentDate)).format("DD/MM/YYYY")} - 
                                 {futureDate == null ||
                                  futureDate == "" ||
                                  futureDate == undefined ||
                                  futureDate == NaN
                                  ? "--"
                                  : moment(new Date(futureDate)).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="Buy-slip1">
                          <div className="">
                            <p>Estimated Daily Yield</p>
                          </div>
                          <div className="text-end">
                            <p>
                              {dailyRewardref.current == null ||
                                dailyRewardref.current == "NaN" ||
                                dailyRewardref.current == undefined ||
                                dailyRewardref.current == NaN ||
                                dailyRewardref.current == ""
                                ? 0
                                : formatter_interest.format(dailyRewardref.current)}{" "} TTT</p>
                          </div>
                        </div>
                        <div className="Buy-slip1">
                          <div className="">
                            <p>Estimated Total Yield</p>
                          </div>
                          <div className="text-end">
                            <p> {" "}
                              {monthlyRewardref.current == null ||
                                monthlyRewardref.current == "NaN" ||
                                monthlyRewardref.current == undefined ||
                                monthlyRewardref.current == ""
                                ? 0
                                : formatter_interest.format(monthlyRewardref.current)}{" "} TTT</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      className={`tab-pane fade ${mytrades === "menu2" ? "show active" : ""
                        }`}
                    >
                      <div className="Buy-section">
                        <div className="betcen">
                          <div>
                            <p>Claimable Rewards</p>
                          </div>
                          {/* <div className="text-end">
                      {" "}
                      <p>Max</p>
                    </div> */}
                        </div>
                        <div className="betcen">
                          <div>
                            <h4>
                              <h4>
                                <img
                                  src={"https://res.cloudinary.com/dh3iko4f6/image/upload/v1718382339/Logo1_wuhkez.png"}
                                  width="40px"
                                />{" "}
                                TTT
                              </h4>
                            </h4>
                          </div>
                          <div className="text-end">
                            {" "}
                            <h4><input
                              type="number"
                              placeholder="Enter Amount"
                              value={claimAmountref.current}
                              onChange={claim_change}
                              onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            /></h4>
                          </div>
                        </div>

                        <div className="betcen">
                          <div>
                            <p>TIC TAC TRUMP</p>
                          </div>
                          <div className="text-end">
                            {" "}
                            <p> Available : {" "}
                              {availableClaimAmountref.current == null ||
                                availableClaimAmountref.current == "NaN" ||
                                availableClaimAmountref.current == undefined ||
                                availableClaimAmountref.current == ""
                                ? 0
                                : formatter.format(availableClaimAmountref.current)}{" "} </p>
                          </div>
                        </div>

                        <h5 className="danger">
                          {claimErrorref.current == true ? Claimmsgref.current : ""}
                        </h5>

                      </div>
                      {
                        WalletStatus == false ?
                          <button className="btn" onClick={handleClickOpen}>Connect Wallet</button>
                          :
                          claimbuttonLoader == true ?
                            <button className="btn">Loading...</button>
                            : !claimAmountref.current || claimAmountref.current == "" || claimAmountref.current == 0 ?
                              <button className="btn">Claim Now</button> :
                              <button className="btn" onClick={claimtype}>Claim Now</button>
                      }

                    </div>


                    <div
                      id="history"
                      role="tabpanel"
                      aria-labelledby="history-tab"
                      className={`tab-pane fade ${mytrades === "menu3" ? "show active" : ""}`}
                    >
                      <div className="Buy-section">
                        <div className="stake-history">
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th className="start">Staked Date</th>
                                <th>Token Amount</th>
                                <th>Total Interest</th>
                                <th className="end">Stake End Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems.length > 0 ? (
                                currentItems.map((item, i) => {
                                  const dates = moment(item.staking_start_date).format("DD-MM-YYYY");
                                  const endDate = moment(item.staking_end_date).format("DD-MM-YYYY");
                                  return (
                                    <tr key={i}>
                                      <td>{dates}</td>
                                      <td>{item.staking_amount} TTT</td>
                                      <td>
                                        {item.interest_rate !== undefined && !isNaN(item.interest_rate)
                                          ? Number(item.interest_rate).toFixed(4)
                                          : 'N/A'}
                                      </td>
                                      <td>{endDate}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center py-4">
                                    <div className="empty_data_img">
                                      <img src={require("../Images/No-data.png")} width="100px" />
                                    </div>
                                    No Data Found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {Staking_historyref.current.length > itemsPerPage && (
                            <div className="pagination">
                              {currentPage === 1 ? <i class="fa-solid fa-circle-arrow-left" onClick={handlePrevClick}></i> : <i class="fa-solid fa-circle-arrow-left text-sec" onClick={handlePrevClick}></i>}


                              {indexOfLastItem >= Staking_historyref.current.length ? <i class="fa-solid fa-circle-arrow-right " onClick={handleNextClick}></i> : <i class="fa-solid fa-circle-arrow-right text-sec" onClick={handleNextClick}></i>}


                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
        open={open1}
        onClose={handlepopClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="wallet-popup"
      >
        <DialogTitle id="alert-dialog-title" className="d-flex justify-content-between">
          <div>
          Connect Wallet
          </div>
        <i class="fa-solid fa-xmark cursor-pointer d-flex align-items-center" onClick={handlepopClose}></i>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <div className="pop-card-head dnones">
          <div className="pop-card" onClick={ConnectWallet}>
            <img src={require('../Images/MetaMask.png')} width="50px" />
            <p>Metamask</p>
          </div>
          <div className="pop-card" onClick={handleWalletConnect}> 
          <img src={require('../Images/walletconnect.png')} width="50px" />
          <p>Wallet Connect</p>
          </div>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          
        </DialogActions>
      </Dialog>
        </section>
      </div>
    </>
  );
}
