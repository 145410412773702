import React, { useEffect, createContext, useContext } from "react";
import useState from "react-usestateref";
import "semantic-ui-css/semantic.min.css";
import axios from "axios";
import { Dropdown } from "semantic-ui-react";
import Header from "./Header";
import { Tooltip } from "react-tooltip";
import env from "../core/service/envconfig";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";
import Web3 from "web3";

import { toast } from "react-toastify";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SwapBNBforTokenABI from "./SwapBNBforTokenABI.json";
import USDTbalanceABI from "./USDTbalanceABI.json";
import ProgressBar from "react-bootstrap/ProgressBar";
import BNBforTokenABI from "./BNBforTokenABI.json";
import { Loading } from "notiflix/build/notiflix-loading-aio";

import "react-tooltip/dist/react-tooltip.css";

import WalletContext from "../WalletContext";

export default function Buy() {
  const projectId = "0b8444fa79de9a3821c5410564de263f";

  const mainnet = {
    chainId: 0x38,
    name: "Binance Smart Chain",
    currency: "BNB",
    explorerUrl: "https://bscscan.com/",
    rpcUrl: "https://bsc-dataseed1.binance.org/",

    // chainId: 0x61,
    // explorerUrl: "https://testnet.bscscan.com/",
    // rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // name: "Binance Smart Chain Testnet",
    // currency: "tBNB",
  };
  const chains = [mainnet];
  const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Solana Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.mywebsite.com/"],
  };
  const ethersConfig = defaultConfig({
    metadata,
    chains,
    projectId,
  });
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
  });

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();
  const [allCurrency, setAllCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("BNB");

  const [bnbBalance, setBnbBalance] = useState(0);
  const [tokenBalance, setTokenBalance, tokenBalanceref] = useState(0);
  const [USDTBalance, setUSDTBalance] = useState(0);

  const [bnbAmount, setBnbAmount] = useState("");
  const [tttAmount, setTttAmount] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [BNBprice, setBNBprice] = useState(0);

  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [swapContract, setSwapContract] = useState(null);

  const { userWallet, setuserWallet } = useContext(WalletContext);

  const [userAccount, setUserAccount] = useState(null);

  const formatter_interest = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 5,
    maximumFractionDigits: 5,
  });

  const calculatePendingPoints = (total, sold) => total - sold;
  const calculatePendingPercentage = (total, sold) => (sold / total) * 100;

  const [PhaseView, setPhaseView] = useState(false);

  const [Phases, setPhases] = useState([]);

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setAllCurrency(currArrayCrypto);
    setSelectedCurrency(currArrayCrypto[0].value);
    FetchUSDTbalance();
    FetchwalletUSDTbalance();
    presale_calculation();
  }, [0]);

  async function FetchUSDTbalance() {
    Loading.hourglass("Processing...", {
      messageColor: "#fff",
      svgColor: "#fff",
      svgSize: "100px",
      backgroundColor: "rgb(27 138 59 / 68%)",
    });
    if (userAccount) {
      // console.log("jnjnjnjFETCH USDT BALANCE");
      const web3Instance = new Web3(window.ethereum);
      const contractInstance = new web3Instance.eth.Contract(
        USDTbalanceABI,
        env.usdtAddres
      );
      const usdtbalance = await contractInstance.methods
        .balanceOf(userAccount)
        .call();

      const ethBalance = web3Instance.utils.fromWei(usdtbalance, "ether");
      setUSDTBalance(ethBalance);
      // console.log(ethBalance);
    }
    Loading.remove();
  }
  async function FetchwalletUSDTbalance() {
    Loading.hourglass("Processing...", {
      messageColor: "#fff",
      svgColor: "#fff",
      svgSize: "100px",
      backgroundColor: "rgb(27 138 59 / 68%)",
    });
    if (userAccount) {
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      const contractInstance = new Contract(
        env.usdtAddres,
        USDTbalanceABI,
        bnbProvider
      );
      console.log(contractInstance, "contractInstance", userAccount);
      const usdtbalance = await contractInstance.balanceOf(userAccount);

      console.log(usdtbalance, "usdtbalance");

      const web3Instance = new Web3(window.ethereum);

      const ethBalance = web3Instance.utils.fromWei(usdtbalance, "ether");

      setUSDTBalance(ethBalance);
      console.log(ethBalance);
    }
    Loading.remove();
  }

  async function presale_calculation() {
    try {
      Loading.hourglass("Processing...", {
        messageColor: "#fff",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });

      var phases = [
        {
          name: "Phase 1",
          totalPoints: 0,
          soldPoints: 0,
          remaining: 0,
          Price: 0.05,
        },
        {
          name: "Phase 2",
          totalPoints: 0,
          soldPoints: 0,
          remaining: 0,
          Price: 0.1,
        },
        {
          name: "Phase 3",
          totalPoints: 0,
          soldPoints: 0,
          remaining: 0,
          Price: 0.2,
        }, // Not started
      ];
      setPhaseView(false);
      var buy_contract_bal = 0;
      var total_supply = 0;
      var presale_supply = 0;
      var total_sold = 0;

      var phase1_supply = 0;
      var phase1_sold = 0;
      var phase1_remaining = 0;

      var phase2_supply = 0;
      var phase2_sold = 0;
      var phase2_remaining = 0;

      var phase3_supply = 0;
      var phase3_sold = 0;
      var phase3_remaining = 0;

      const provider = new Web3.providers.HttpProvider(env.rpc_url);
      const web3 = new Web3(provider);
      const contractInstance = new web3.eth.Contract(
        BNBforTokenABI,
        env.tokenAddres
      );

      const supply_response = await contractInstance.methods
        .totalSupply("")
        .call();

      total_supply = web3.utils.fromWei(supply_response, "mwei");

      const balance_response = await contractInstance.methods
        .balanceOf(env.BuyAddress)
        .call();

      buy_contract_bal = web3.utils.fromWei(balance_response, "mwei");

      // console.log("buy_contract_bal",buy_contract_bal)

      // console.log("total_supply",total_supply);

      presale_supply = total_supply * 0.2;

      // console.log("presale_supply",presale_supply);

      total_sold = +presale_supply - +buy_contract_bal;

      // console.log("remaining_token",total_sold);

      phase1_supply = presale_supply * 0.04;

      phase2_supply = presale_supply * 0.06;

      phase3_supply = presale_supply * 0.1;

      phase1_sold = +total_sold;

      phase2_sold = 0;

      phase3_sold = 0;

      phase1_remaining = +phase1_supply - +total_sold;

      phase2_remaining = +phase2_supply;

      phase3_remaining = +phase3_supply;

      phases[0]["totalPoints"] = +phase1_supply;

      phases[1]["totalPoints"] = +phase2_supply;

      phases[2]["totalPoints"] = +phase3_supply;

      phases[0]["soldPoints"] = +phase1_sold;

      phases[1]["soldPoints"] = +phase2_sold;

      phases[2]["soldPoints"] = +phase3_sold;

      phases[0]["remaining"] = +phase1_remaining;

      phases[1]["remaining"] = +phase2_remaining;

      phases[2]["remaining"] = +phase3_remaining;

      console.log("phases", phases);

      setPhases(phases);

      Loading.remove();

      setPhaseView(true);
    } catch (error) {
      console.log("presale error", error);
    }
  }

  async function getBalance() {
    try {
      const web3Instance = new Web3(window.ethereum);
      const account = localStorage.getItem("userAddress");
      const weiBalance = await web3Instance.eth.getBalance(account);
      const ethBalance = web3Instance.utils.fromWei(weiBalance, "ether");
      setBnbBalance(ethBalance);
      localStorage.setItem("Balance", ethBalance);
    } catch (error) {
      // console.log("---","balance fet error",error)
    }
  }

  async function getBalance1() {
    try {
      const account = localStorage.getItem("userAddress");
      const provider = new BrowserProvider(walletProvider);

      const balance = await provider.getBalance(account);
      const ethBalance = parseInt(balance) / 1000000000000000000;
      setBnbBalance(ethBalance);
      localStorage.setItem("Balance", ethBalance);
    } catch (error) {
      // console.log("---","balance fet error",error)
    }
  }
  function checkForPhantomWallet() {
    if (typeof window.solana !== "undefined" && window.solana.isPhantom) {
      console.log(
        "Phantom Wallet detected. Please disable it for MetaMask testing."
      );
      alert(
        "Phantom Wallet is detected. Please disable Phantom Wallet extension for  Use  MetaMask ."
      );
      return true;
    } else {
      return false;
    }
  }

  async function connectToMetaMask() {
    handlepopClose();
    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });

      if (!checkForPhantomWallet()) {
        if (
          typeof window.ethereum !== "undefined" &&
          window.ethereum.isMetaMask
        ) {
          try {
            await window.ethereum.request({ method: "eth_requestAccounts" });

            const bscNetwork = {
              chainId: "0x38",
              chainName: "Binance Smart Chain",
              rpcUrls: [env.rpc_url],
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://bscscan.com"],
            };

            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: bscNetwork.chainId }],
            });

            const web3Instance = new Web3(window.ethereum);
            // console.log(web3Instance);
            setWeb3(web3Instance);

            const accounts = await web3Instance.eth.getAccounts();
            // console.log(accounts[0]);
            setUserAccount(accounts[0]);

            // Store address in local storage
            localStorage.setItem("userAddress", accounts[0]);

            const weiBalance = await web3Instance.eth.getBalance(accounts[0]);
            const ethBalance = web3Instance.utils.fromWei(weiBalance, "ether");
            setBnbBalance(ethBalance);
            localStorage.setItem("Balance", ethBalance);
            Loading.remove();
            localStorage.setItem("wallettype", "metamask");
            FetchUSDTbalance();
          } catch (error) {
            // console.error(error);
            Loading.remove();
          }
        } else {
          Loading.remove();
        }
      } else {
        Loading.remove();
      }
    } catch (error) {
      // console.error(error);
      Loading.remove();
    }
  }

  const handleWalletConnect = async () => {
    handlepopClose();
    await open();
    try {
      Loading.hourglass("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setUserAccount(address);
        const balance = await provider.getBalance(address);
        localStorage.setItem("userAddress", address);
        const ethBalance = parseInt(balance) / 1000000000000000000;
        setBnbBalance(ethBalance);
        localStorage.setItem("Balance", ethBalance);

        const bnbProvider = new JsonRpcProvider(env.rpc_url);

        const Tokencontarct = new Contract(
          env.tokenAddres,
          BNBforTokenABI,
          bnbProvider
        );
        const tokenBalance = await Tokencontarct.balanceOf(address);

        localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000);
        localStorage.setItem("wallettype", "wallet-connect");
        FetchwalletUSDTbalance();
        initializeWeb3();
        Loading.remove();
      } catch (error) {
        console.error(error);
        Loading.remove();
      }
    } catch (err) {
      Loading.remove();
    }
  };

  useEffect(() => {
    initializeWeb3();
    const intervalId = setInterval(initializeWeb3, 2000);
    return () => clearInterval(intervalId);
  }, [0]);

  const initializeWeb3 = async () => {
    const userAddress = localStorage.getItem("userAddress");

    if (userAddress != null) {
      const Bnbbalance = localStorage.getItem("Balance");
      const TTTbalance = localStorage.getItem("TTTBalance");

      setUserAccount(userAddress);
      setTokenBalance(TTTbalance);
      setBnbBalance(Bnbbalance);

      const wallettype = localStorage.getItem("wallettype");

      if (wallettype === "metamask") {
        getBalance();
      } else {
        getBalance1();
      }

      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      const contractInstance = new web3Instance.eth.Contract(
        SwapBNBforTokenABI,
        env.BuyAddress
      );
      setSwapContract(contractInstance);

      if (wallettype === "metamask") {
        const TokenContract = new web3Instance.eth.Contract(
          BNBforTokenABI,
          env.tokenAddres
        );
        setContract(TokenContract);

        try {
          const tokenBalance = await TokenContract.methods
            .balanceOf(userAddress)
            .call();
          setTokenBalance(Number(tokenBalance) / 1000000);
        } catch (error) {
          console.error("Error fetching token balance:", error);
        }
      } else {
        const bnbProvider = new JsonRpcProvider(env.rpc_url);

        if (walletProvider) {
          try {
            const provider = new BrowserProvider(walletProvider);
            console.log(provider, "provider");
            const signer = await provider.getSigner();

            const contract1 = new Contract(
              env.tokenAddres,
              BNBforTokenABI,
              signer
            );

            
            setContract(contract1);

            console.log(contract1, "contract1");

            const tokenBalance = await contract1.balanceOf(userAddress);
            setTokenBalance(Number(tokenBalance) / 1000000);

            localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000);
          } catch (error) {
            console.error(
              "Error initializing provider or fetching token balance:",
              error
            );
          }
        } else {
          console.error("walletProvider is null or undefined");
        }
      }
    } else {
      setUserAccount("");
      setTokenBalance(0);
      setBnbBalance(0);
    }
  };

  useEffect(() => {
    const getUsdtPrice = async () => {
      Loading.hourglass("Processing...", {
        messageColor: "#fff",
        svgColor: "#fff",
        svgSize: "100px",
        backgroundColor: "rgb(27 138 59 / 68%)",
      });
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=BNB&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      const response = await axios.get(apiUrl);
      if (response.data && response.data.BNB) {
        const value = response.data.BNB.USDT;
        let unitCalculation;

        if (selectedCurrency === "BNB") {
          unitCalculation = (value * 1) / +env.TTTusdtprice;
          setBNBprice(value.toFixed(4));
          setUsdtPrice(unitCalculation.toFixed(4));
        } else {
          unitCalculation = 1 / +env.TTTusdtprice;
          setBNBprice(1);
          setUsdtPrice(unitCalculation.toFixed(4));
        }
      }
      Loading.remove();
    };

    getUsdtPrice();
  }, [selectedCurrency]);

  useEffect(() => {
    const calculatedTttAmount = bnbAmount * usdtPrice;
    setTttAmount(calculatedTttAmount);
  }, [bnbAmount, usdtPrice]);

  const buytype = () => {
    const wallettype = localStorage.getItem("wallettype");

    if (wallettype == "metamask") {
      handleBuy();
    } else {
      connectwalletBuy();
    }
  };

  const handleBuy = async () => {
    try {
      let Buy;

      if (tttAmount > 1) {
        if (selectedCurrency == "USDT") {
          if (USDTBalance < 0 || bnbAmount > USDTBalance) {
            toast.error("Insufficient USDT Balance");
          } else {
            Loading.hourglass("Processing...", {
              messageColor: "#fff",
              svgColor: "#fff",
              svgSize: "100px",
              backgroundColor: "rgb(27 138 59 / 68%)",
            });
            // console.log("call usdt");
            const web3_usdt = new Web3(window.ethereum);
            const tokenContract_usdt = new web3_usdt.eth.Contract(
              USDTbalanceABI,
              env.usdtAddres
            );
            const weiValue = web3.utils.toWei(bnbAmount.toString(), "ether");
            // const weiValue = bnbAmount * 1000000000000000000;
            const gasPrice = await web3.eth.getGasPrice();

            // const receiveamount = tttAmount * 1000000;

            const receiveamount = web3.utils.toWei(tttAmount.toString(), "mwei");

            // console.log(typeof receiveamount);

            var fees = (bnbAmount * 1) / 100;
            var remaining_amount = bnbAmount - fees;
            // fees = fees * 1000000000000000000;
            // remaining_amount = remaining_amount * 1000000000000000000;
            fees = web3.utils.toWei(fees.toString(), "ether");
            remaining_amount = web3.utils.toWei(
              remaining_amount.toString(),
              "ether"
            );

            var Approved = await tokenContract_usdt.methods
              .approve(env.BuyAddress, weiValue)
              .send({ from: userAccount });

            if (Approved) {
              Buy = await swapContract.methods
                .buy_usdt(
                  userAccount,
                  receiveamount,
                  weiValue,
                  fees,
                  remaining_amount
                )
                .send({
                  from: userAccount,
                  gas: 1000000, // Use estimated gas
                  gasPrice: gasPrice, // Use current gas price
                });

              FetchUSDTbalance();
              Loading.remove();

              // console.log(Buy, "Transaction successful");

              if (Buy) {
                toast.success("Token Purcashed Sucessfully");
                setBnbAmount("");
              }

              const updatedTokenBalance = await contract.methods
                .balanceOf(userAccount)
                .call();
              setTokenBalance(updatedTokenBalance / 1000000);
            }
          }
        } else {
          if (bnbBalance < 0 || bnbAmount > bnbBalance) {
            toast.error("Insufficient BNB Balance");
          } else {
            Loading.hourglass("Processing...", {
              messageColor: "#fff",
              svgColor: "#fff",
              svgSize: "100px",
              backgroundColor: "rgb(27 138 59 / 68%)",
            });
            // console.log("call bnb");
            const weiValue = web3.utils.toWei(bnbAmount.toString(), "ether");
            const gasPrice = await web3.eth.getGasPrice();

            // const receiveamount = tttAmount * 1000000;

            const receiveamount = web3.utils.toWei(tttAmount.toString(), "mwei");

            // console.log(typeof receiveamount);

            var fees = (bnbAmount * 1) / 100;
            var remaining_amount = bnbAmount - fees;
            fees = web3.utils.toWei(fees.toString(), "ether");
            remaining_amount = web3.utils.toWei(
              remaining_amount.toString(),
              "ether"
            );

            Buy = await swapContract.methods
              .buyToken(
                userAccount,
                receiveamount,
                weiValue,
                fees,
                remaining_amount
              )
              .send({
                from: userAccount,
                value: weiValue,
                gas: 1000000, // Use estimated gas
                gasPrice: gasPrice, // Use current gas price
              });

            // console.log(Buy, "Transaction successful");

            Loading.remove();

            FetchUSDTbalance();
            if (Buy) {
              toast.success("Token Purcashed Sucessfully");
              setBnbAmount("");
            }
            const updatedTokenBalance = await contract.methods
              .balanceOf(userAccount)
              .call();
            setTokenBalance(updatedTokenBalance / 1000000);
          }
        }
      } else {
        toast.error("Buy Minimum 1 TTT Token");
      }
    } catch (error) {
      Loading.remove();
      // console.error("Error executing transaction:", error);
    }
  };

  const connectwalletBuy = async () => {
    Loading.hourglass("Processing...", {
      messageColor: "#fff",
      svgColor: "#fff",
      svgSize: "100px",
      backgroundColor: "rgb(27 138 59 / 68%)",
    });

    try {
      let Buy;

      if (tttAmount > 1) {
        const bnbProvider = new JsonRpcProvider(env.rpc_url);

        const provider = new BrowserProvider(walletProvider);

        const signer = await provider.getSigner();

        console.log(signer, "signers");

        const tokenContract_usdt = new Contract(
          env.usdtAddres,
          USDTbalanceABI,
          signer
        );

        const swapContract1 = new Contract(
          env.BuyAddress,
          SwapBNBforTokenABI,
          signer
        );

        const contract1 = new Contract(env.tokenAddres, BNBforTokenABI, signer);

        if (selectedCurrency == "USDT") {
          if (USDTBalance < 0 || bnbAmount > USDTBalance) {
            toast.error("Insufficient USDT Balance");
            Loading.remove();

          } else {
            console.log("call usdt");

            const weiValue = bnbAmount * 1000000000000000000;

            console.log(weiValue, "weiValue");

            // const receiveamount = tttAmount * 1000000;
            const receiveamount = web3.utils.toWei(tttAmount.toString(), "mwei");
            console.log(receiveamount, "receiveamount");

            var fees = (bnbAmount * 1) / 100;
            console.log(fees, "fees");

            var remaining_amount = bnbAmount - fees;
            fees = web3.utils.toWei(fees.toString(), "ether");
            remaining_amount = web3.utils.toWei(
              remaining_amount.toString(),
              "ether"
            );

            console.log(fees, "fees", tokenContract_usdt);

            const gasPrice = (await provider.getFeeData()).gasPrice;
            // const gasPrice1 = await web3.eth.getGasPrice();


            console.log(gasPrice, "jbcjbdcjjkcsbjdcsbcdbkj", gasPrice);

            try {
              var Approved = await tokenContract_usdt.approve(
                env.BuyAddress,
                weiValue.toString()
              );

              console.log(Approved, "Approved");

            


              if (Approved) {
                Buy = await swapContract1.buy_usdt(
                  userAccount,
                  receiveamount,
                  weiValue.toString(),
                  fees,
                  remaining_amount,
                  {
                    gasLimit: 1000000, // Use estimated gas
                    gasPrice: gasPrice, // Use current gas price
                  }
                );

                console.log(Buy, "Buy");

                FetchwalletUSDTbalance();
                Loading.remove();

                if (Buy) {
                  toast.success("Token Purcashed Sucessfully");
                  setBnbAmount("");
                  Loading.remove();
                }

                const Tokencontarct = new Contract(
                  env.tokenAddres,
                  BNBforTokenABI,
                  bnbProvider
                );
                const tokenBalance = await Tokencontarct.balanceOf(userAccount);
                console.log(tokenBalance, "tokenBalance");

                localStorage.setItem(
                  "TTTBalance",
                  Number(tokenBalance) / 1000000
                );
                setTokenBalance(Number(tokenBalance) / 1000000);
              }
            } catch (error) {
              console.log("error", error);
              Loading.remove();

              toast.error("Transaction failed");
            }
          }
        } else {

          if (bnbBalance < 0 || bnbAmount > bnbBalance) {
            toast.error("Insufficient BNB Balance");
            Loading.remove();

          } else {
            const weiValue = bnbAmount * 1000000000000000000;

            console.log(weiValue, "weiValue");

            // const receiveamount = tttAmount * 1000000;

            const receiveamount = web3.utils.toWei(tttAmount.toString(), "mwei");

            console.log(receiveamount, "receiveamount");

            var fees = (bnbAmount * 1) / 100;
            var remaining_amount = bnbAmount - fees;
            fees = web3.utils.toWei(fees.toString(), "ether");
            remaining_amount = web3.utils.toWei(
              remaining_amount.toString(),
              "ether"
            );

            console.log(remaining_amount, "remaining_amount", fees);

            const gasPrice = (await provider.getFeeData()).gasPrice;

            // const gasPrice = await web3.eth.getGasPrice();




            try {
              console.log(Number(gasPrice), "uuhuhuhuh");
              const transaction = await swapContract1.buyToken(
                userAccount,
                receiveamount,
                weiValue.toString(),
                fees,
                remaining_amount,
                {
                  value: weiValue.toString(),
                  gasLimit: 1000000, // Use estimated gas
                gasPrice: gasPrice, // Use current gas price
                }
              );

              console.log("Transaction successful:", transaction);

              if (transaction) {
                toast.success("Token Purcashed Sucessfully");
                setBnbAmount("");
                Loading.remove();
              }
              // const updatedTokenBalance = await contract1.methods
              // .balanceOf(userAccount)
              // .call();

              console.log(contract1, "contract");

              const Tokencontarct = new Contract(
                env.tokenAddres,
                BNBforTokenABI,
                bnbProvider
              );
              const tokenBalance = await Tokencontarct.balanceOf(userAccount);

              console.log(tokenBalance, "tokenBalance");

              localStorage.setItem("TTTBalance", Number(tokenBalance) / 1000000);

              setTokenBalance(Number(tokenBalance) / 1000000);
              FetchwalletUSDTbalance();
            } catch (transactionError) {
              console.error("Error executing transaction:", transactionError);
              toast.error("Transaction failed");
              Loading.remove();
            }

          }
        }
      } else {
        Loading.remove();

        toast.error("Buy Minimum 1 TTT Token");
      }
    } catch (error) {
      console.error("Error executing transaction:", error);
    }
  };

  const selectToken = (value) => {
    const wallettype = localStorage.getItem("wallettype");

    if (wallettype == "metamask") {
      FetchUSDTbalance();
    } else {
      FetchwalletUSDTbalance();
    }
    setSelectedCurrency(value);
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    const targetDateUTC = new Date("2024-07-15T00:00:00Z"); // 00:00:00 EST is 04:00:00 UTC

    const updateTimer = () => {
      const nowUTC = new Date();
      const difference = targetDateUTC - nowUTC;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };
    const timerId = setInterval(updateTimer, 1000);
    return () => clearInterval(timerId);
  }, []);

  const [open1, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlepopClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header />
      <div className="Buy-page">
        <section className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 my-5 d-flex align-items-center">
              <div className="presale-buy mt-5">
                <h4>PRE-SALE EVENT</h4>
                <h6>BUY BEFORE NEXT PRICE INCREASE</h6>

                <h6 className="my-3">
                  Launching on the day of the Republican National Convention, the TicTacTrump Coin is set to go live!
                </h6>

                <div className="countdown mt-4">
                  <div className="day">
                    <p> {timeLeft.days}</p>{" "}
                    <span>{timeLeft.days == 1 ? "Day" : "Days"}</span>
                  </div>
                  <div className="hour">
                    <p> {timeLeft.hours}</p>{" "}
                    <span>{timeLeft.days == 1 ? "Hour" : "Hours"}</span>
                  </div>
                  <div className="min">
                    <p> {timeLeft.minutes}</p>{" "}
                    <span>{timeLeft.days == 1 ? "Minute" : "Minutes"}</span>
                  </div>
                  <div className="sec">
                    <p> {timeLeft.seconds}</p>{" "}
                    <span>{timeLeft.days == 1 ? "Seconds" : "Seconds"}</span>
                  </div>
                </div>

                {PhaseView == true
                  ? Phases.map((phase, index) => {
                    const pendingPoints = calculatePendingPoints(
                      phase.totalPoints,
                      phase.soldPoints
                    );
                    const pendingPercentage = calculatePendingPercentage(
                      phase.totalPoints,
                      phase.soldPoints
                    );

                    return (
                      <div
                        key={index}
                        className="mt-4"
                        style={{ marginBottom: "2rem" }}
                      >
                        <div className="d-flex justify-content-between">
                          <Typography variant="h6">{phase.name}</Typography>
                          <Typography variant="h6">
                            Sold : {parseFloat(phase.soldPoints).toFixed(3)}{" "}
                            TTT / {parseFloat(phase.remaining).toFixed(3)} TTT
                          </Typography>

                          <Typography variant="h6">
                            {" "}
                            Price : $ {phase.Price}
                          </Typography>
                        </div>

                        <div>
                          <ProgressBar
                            animated
                            variant="warning"
                            now={pendingPercentage}
                          />
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </div>
            </div>
            <div className="col-lg-6 my-5">
              <div className="buy-card">
                <button>Buy</button>
                <div className="Buy-section">
                  <div className="betcen">
                    <div>
                      <p>You Pay</p>
                    </div>
                    <div className="text-end">
                      <p>
                        Balance :{" "}
                        {selectedCurrency === "BNB" ? (
                          <>{formatter_interest.format(bnbBalance)} BNB</>
                        ) : (
                          <> {formatter_interest.format(USDTBalance)} USDT</>
                        )}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="betcen">
                    <div>
                      <h4>
                        <Dropdown
                          fluid
                          value={selectedCurrency}
                          selection
                          options={allCurrency}
                          onChange={(e, { value }) => selectToken(value)}
                        />
                      </h4>
                    </div>
                    <div className="text-end w-50">
                      <h4>
                        <input
                          type="number"
                          onKeyDown={(evt) =>
                            [
                              "ArrowUp",
                              "ArrowDown",
                              "e",
                              "E",
                              "+",
                              "-",
                            ].includes(evt.key) && evt.preventDefault()
                          }
                          value={bnbAmount}
                          placeholder="Enter Amount"
                          className="w-100"
                          onChange={(e) => {
                            setBnbAmount(e.target.value);
                          }}
                        />
                      </h4>
                    </div>
                  </div>
                  <div className="betcen">
                    <div>
                      <p>{selectedCurrency}</p>
                    </div>
                    <div className="text-end">
                      <p>$ {(BNBprice * bnbAmount).toFixed(4)}</p>
                    </div>
                  </div>
                </div>
                <div className="Buy-section">
                  <div className="betcen">
                    <div>
                      <p>You receive</p>
                    </div>
                    <div className="text-end">
                      <p>
                        Balance :{" "}
                        {formatter_interest.format(tokenBalanceref.current)} TTT
                      </p>
                    </div>
                  </div>
                  <div className="betcen">
                    <div>
                      <h4>
                        <img
                          src={
                            "https://res.cloudinary.com/dh3iko4f6/image/upload/v1718382339/Logo1_wuhkez.png"
                          }
                          width="40px"
                        />{" "}
                        TTT
                      </h4>
                    </div>
                    <div className="text-end">
                      <h4>{tttAmount.toFixed(4)}</h4>
                    </div>
                  </div>
                  <div className="betcen">
                    <div>
                      <p>TIC TAC TRUMP</p>
                    </div>
                    <div className="text-end">
                      <p>$ {(0.05 * tttAmount).toFixed(4)}</p>
                    </div>
                  </div>
                </div>
                {userAccount ? (
                  <>
                    <button className="btn" onClick={buytype}>
                      Buy TTT{" "}
                    </button>
                  </>
                ) : (
                  <>
                    <button className="btn" onClick={handleClickOpen}>
                      Connect Wallet{" "}
                    </button>
                  </>
                )}
                <div className="Buy-slip">
                  <h4>
                    1 {selectedCurrency} = {usdtPrice} TTT ($ {BNBprice})
                  </h4>
                  <div className="Buy-slip1">
                    <p data-tooltip-id="tooltip" href="#">
                      TRUMP Fees
                    </p>
                    <p>1%</p>
                  </div>
                  <Tooltip
                    id="tooltip"
                    place="right-start"
                    effect="solid"
                    className="Tooltips"
                  >
                    Certain tokens have an acquisition or sale fee that is
                    determined by the token issuer. There are none of these fees
                    assessed by TTT.
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            open={open1}
            onClose={handlepopClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="wallet-popup"
          >
            <DialogTitle
              id="alert-dialog-title"
              className="d-flex justify-content-between"
            >
              <div>Connect Wallet</div>
              <i
                class="fa-solid fa-xmark cursor-pointer d-flex align-items-center"
                onClick={handlepopClose}
              ></i>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="pop-card-head">
                  <div className="pop-card dnones" onClick={connectToMetaMask}>
                    <img src={require("../Images/MetaMask.png")} width="50px" />
                    <p>Metamask</p>
                  </div>
                  <div className="pop-card" onClick={handleWalletConnect}>
                    <img
                      src={require("../Images/walletconnect.png")}
                      width="50px"
                    />
                    <p>Wallet Connect</p>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </section>
      </div>
    </>
  );
}
